<template>
  <div v-if="listChannelOfflineAndDistri.includes(fulfillmentChannelCode)">
    <div class="pagebreak">
      <div class="label-shipment-content px-3">
        <div class="unboxing-warning py-2">
          <img src="@/assets/images/unboxing-warning.webp" width="100%" />
        </div>
        <div class="label-shipment-content-border">
          <div class="my-2">
            <a-row class="d-flex">
              <a-col
                :span="6"
                style="margin: 0 10px; font-weight: bold; text-align: left; font-size: 12px;"
              >
                <div class="d-flex">
                  Kurir
                </div>
              </a-col>
              <a-col
                :span="18"
                style="margin: 0 10px; font-weight: bold; text-align: left; font-size: 12px;"
              >
                <div class="d-flex">
                  : {{
                    [
                      shipping?.courier, shipping?.service_name
                    ].filter((item) => item).join(' - ')
                  }}
                </div>
              </a-col>
            </a-row>
            <a-row class="d-flex">
              <a-col
                :span="6"
                style="margin: 0 10px; font-weight: bold; text-align: left; font-size: 12px;"
              >
                <div class="d-flex">
                  Ongkos Kirim
                </div>
              </a-col>
              <a-col
                :span="18"
                style="margin: 0 10px; font-weight: bold; text-align: left; font-size: 12px;"
              >
                <div class="d-flex">
                  : {{ shipping.shipping_cost?.value | currency }}
                </div>
              </a-col>
            </a-row>
          </div>
          <template v-if="isAwbExist">
            <div class="d-flex justify-items-center text-center justify-content-center w-100">
              <div class="d-flex flex-column w-100 mx-2 mb-1">
                <div class="bold awb-tag-name py-1" style="margin-top: 0px">
                  <span style="font-weight: normal; letter-spacing: 0px; padding-right: 10px;">AWB:</span>{{ shipping.awb }}
                </div>
              </div>
            </div>
            <div class="d-flex justify-items-center text-center justify-content-center mb-3 w-100">
              <VueBarcode
                v-model="shipping.awb"
                :options="barcode"
                style="max-width: 500px; max-height: 80px;"
              />
            </div>
          </template>
          <a-row
            class="d-flex"
            style="
              padding-top: 10px;
              font-size: 12px;
              text-align: left;
              border-top: 1px dashed #cccccc;
            "
          >
            <a-col :span="24" style="margin: 0px 10px; text-align: left; font-size: 12px; width: fit-content;">
              <div class="d-flex" style="font-weight: bold">
                Pengirim: 
                <span class="ml-2">
                  {{ sender?.name || shipment?.name || salesData?.business?.business_title || '' }}
                </span>
              </div>
              <div class="d-flex">
                {{ sender?.address || shipment?.address || '' }}
              </div>
              <div class="d-flex">
                {{ sender?.phone || shipment?.phone || '' }}
              </div>
            </a-col>
          </a-row>
          <a-row class="d-flex">
            <a-col :span="24" style="margin: 10px 10px 0; text-align: left; font-size: 12px; width: fit-content;">
              <div class="d-flex" style="font-weight: bold">
                Penerima
              </div>
              <div class="d-flex">
                {{ destination?.name || '-' }}
              </div>
              <div class="d-flex" style="word-break: break-word;">
                <template v-if="destination.address">
                  {{ destination.address?.line1 }}
                  {{ destination.address?.line2 }},
                  {{ destination.address?.sub_district }},
                  {{ destination.address?.district }},
                  {{ destination.address?.province }},
                  {{ destination.address?.postal_code }}
                </template>
                <template v-else>
                  -
                </template>
              </div>
              <div class="d-flex">
                {{ destination?.phone || '-' }}
              </div>
            </a-col>
          </a-row>
          <div
            v-if="printFulfillmentId"
            style="
              margin-top: 10px;
              font-weight: 600;
              font-size: 14px;
              text-align: center;
              border-top: 1px dashed #cccccc;
            "
          >
            <a-row :gutter="16">
              <a-col :span="24">
                <div v-if="isExist" style="margin-top: 10px">
                  <VueBarcode
                    v-model="order.order_number"
                    :options="barcode"
                    style="max-width: 500px; max-height: 80px;"
                  />
                </div>
                <div v-if="isExist" style="margin-bottom: 15px;">
                  No. Order: {{ order.order_number }}
                </div>
              </a-col>
            </a-row>
          </div>
          <div
            style="
              margin: 10px 0px;
              padding-top: 10px;
              font-size: 12px;
              text-align: left;
              border-top: 1px dashed #cccccc;
            "
          >
            <a-row class="d-flex">
              <a-col
                :span="6"
                style="margin: 0 10px; font-weight: bold; text-align: left"
              >
                <div class="d-flex">
                  No Order
                </div>
              </a-col>
              <a-col
                :span="18"
                style="margin: 0 10px; font-weight: bold; text-align: left"
              >
                <div class="d-flex">
                  : {{ order.order_number }}
                </div>
              </a-col>
            </a-row>
            <a-row class="d-flex">
              <a-col
                :span="6"
                style="margin: 0 10px; font-weight: bold; text-align: left"
              >
                <div class="d-flex">
                  Tanggal Order
                </div>
              </a-col>
              <a-col
                :span="18"
                style="margin: 0 10px; font-weight: bold; text-align: left"
              >
                <div class="d-flex">
                  : {{ parseISOString(order.order_date) }}
                </div>
              </a-col>
            </a-row>
            <a-row v-if="isShowValueOrder" class="d-flex">
              <a-col
                :span="6"
                style="margin: 0 10px; font-weight: bold; text-align: left"
              >
                <div class="d-flex">
                  Sub Total Order
                </div>
              </a-col>
              <a-col
                :span="18"
                style="margin: 0 10px; font-weight: bold; text-align: left"
              >
                <div class="d-flex">
                  : {{ salesData?.sub_total | currency }}
                </div>
              </a-col>
            </a-row>
          </div>
          <div
            style="
              margin: 10px 0px;
              padding-top: 10px;
              font-size: 12px;
              text-align: left;
              border-top: 1px dashed #cccccc;
            "
          >
            <a-row class="d-flex">
              <a-col
                :span="24"
                style="margin: 0 10px; text-align: left"
              >
                <div class="d-flex">
                  Catatan :
                </div>
              </a-col>
            </a-row>
          </div>
          <div
            v-if="printOrder"
            style="
              margin: 10px 0px;
              font-size: 12px;
              text-align: left;
              border-top: 1px solid #cccccc;
            "
          >
            <a-row class="d-flex" style="width: 100%">
              <a-col :span="20" style="margin: 10px 10px">
                <div>Produk</div>
              </a-col>
              <a-col :span="2" style="margin: 10px 0">
                <div>Jumlah</div>
              </a-col>
            </a-row>
            <a-row v-for="(item, index) in product" :key="index" class="d-flex" style="width: auto;">
              <a-col :span="20" style="margin: 1px 10px">
                <div>{{ item.catalog_title }}</div>
              </a-col>
              <a-col :span="2" style="margin: 1px 0">
                <div style="text-align: center">
                  <b>{{ item.quantity }}</b> {{ item.uom }}
                </div>
              </a-col>
            </a-row>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="['tokopedia_id', 'tokpedia_id'].includes(fulfillmentChannelCode)">
    <div class="pagebreak">
      <td class="page-section shipping-label" style="height: 563pt">
        <div class="unboxing-warning" style="margin-bottom: 10px">
          <img src="@/assets/images/unboxing-warning.webp" width="100%" />
        </div>
        <div class="label-shipment-content-border mt-2">
          <div class="my-2">
            <div class="d-flex justify-content-between align-items-center px-2">
              <div class="w-25">
                <img
                  width="100%"
                  height="50"
                  style="object-fit: contain; object-position: left;"
                  :src="getMarketPlaceLogo(order?.channel?.code)"
                  alt="MP Logo"
                />
              </div>
              <div class="w-25 text-right">
                <div style="font-weight: bold; font-size: 14px;" />
              </div>
            </div>
          </div>
          <div
            class="py-2 px-2 d-flex justify-content-between align-items-center"
            style="
              font-size: 12px;
              border-top: 1px dashed #cccccc;
            "
          >
            <div>
              <img
                width="100%"
                height="30"
                style="object-fit: contain; object-position: right;"
                :src="getCourierLogo(shipping?.courier)"
                alt="Courier Logo"
              />
            </div>
            <div>
              <div><b>{{ shipping?.courier }}</b></div>
              <div class="mt-1">
                <b>{{ shipping?.service_type || shipping?.service_name }}</b>
              </div>
            </div>
            <div>
              <div />
              <div class="mt-1">
                <b />
              </div>
            </div>
            <div>
              <div>Cost</div>
              <div class="mt-1">
                <b>{{ shipping?.shipping_cost?.value | currency }}</b>
              </div>
            </div>
          </div>
          <template v-if="isAwbExist">
            <div class="d-flex justify-items-center text-center justify-content-center w-100">
              <div class="d-flex flex-column w-100 mx-2 mb-1">
                <div class="bold awb-tag-name py-1" style="margin-top: 0px; font-size: 14px;">
                  {{ shipping.awb }}
                </div>
              </div>
            </div>
            <div class="d-flex justify-items-center text-center justify-content-center mb-3 w-100">
              <VueBarcode
                v-model="shipping.awb"
                :options="barcode"
                style="max-width: 500px; max-height: 80px;"
              />
            </div>
          </template>
          <a-row
            class="px-2"
            style="
              padding-top: 10px;
              font-size: 12px;
              text-align: left;
              border-top: 1px dashed #cccccc;
            "
          >
            <a-col :span="12">
              <a-row
                class="d-flex"
              >
                <a-col :span="24" style="text-align: left; font-size: 12px; width: fit-content;">
                  <div style="font-weight: bold">
                    <div>Pengirim:</div>
                    <div class="mt-1">
                      {{ sender?.name || shipment?.name || salesData?.business?.business_title || '' }}
                    </div>
                  </div>
                  <div class="d-flex">
                    {{ sender?.address || shipment?.address || '' }}
                  </div>
                  <div class="d-flex">
                    {{ sender?.phone || shipment?.phone || '' }}
                  </div>
                </a-col>
              </a-row>
            </a-col>
            <a-col :span="12">
              <a-row class="d-flex">
                <a-col :span="24" style="text-align: left; font-size: 12px; width: fit-content;">
                  <div class="d-flex" style="font-weight: bold">
                    Penerima:
                  </div>
                  <div class="d-flex mt-1">
                    {{ destination?.name || '-' }}
                  </div>
                  <div class="d-flex">
                    <template v-if="destination.address">
                      {{ destination.address?.line1 }}
                      {{ destination.address?.line2 }},
                      {{ destination.address?.sub_district }},
                      {{ destination.address?.district }},
                      {{ destination.address?.province }},
                      {{ destination.address?.postal_code }}
                    </template>
                    <template v-else>
                      -
                    </template>
                  </div>
                  <div class="d-flex">
                    {{ destination?.phone || '-' }}
                  </div>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
          
          <div
            v-if="printFulfillmentId"
            style="
              margin-top: 10px;
              font-weight: 600;
              font-size: 14px;
              text-align: center;
              border-top: 1px dashed #cccccc;
            "
          >
            <a-row :gutter="16">
              <a-col :span="24">
                <div v-if="isExist" style="margin-top: 10px">
                  <VueBarcode
                    v-model="order.order_number"
                    :options="barcode"
                    style="max-width: 500px; max-height: 80px;"
                  />
                </div>
                <div v-if="isExist" style="margin-bottom: 15px;">
                  {{ order.order_number }}
                </div>
              </a-col>
            </a-row>
          </div>
          <div
            v-if="printOrder"
            style="
              margin: 10px 0px;
              font-size: 12px;
              text-align: left;
              border-top: 1px dashed #cccccc;
            "
          >
            <a-row class="d-flex" style="width: 100%">
              <a-col :span="20" style="margin: 10px 10px">
                <div>Produk</div>
              </a-col>
              <a-col :span="2" style="margin: 10px 0">
                <div>Jumlah</div>
              </a-col>
            </a-row>
            <a-row v-for="(item, index) in product" :key="index" class="d-flex" style="width: auto;">
              <a-col :span="20" style="margin: 1px 10px">
                <div>{{ item.catalog_title }}</div>
              </a-col>
              <a-col :span="2" style="margin: 1px 0">
                <div style="text-align: center">
                  <b>{{ item.quantity }}</b> {{ item.uom }}
                </div>
              </a-col>
            </a-row>
          </div>
          <div
            class="p-2"
            style="
              margin-top: 10px;
              font-weight: 600;
              font-size: 12px;
              border-top: 1px dashed #cccccc;
            "
          >
            <a-row :gutter="16">
              <a-col :span="24">
                <div>{{ !!shipping?.code_value?.value ? '' : 'NON ' }}COD</div>
              </a-col>
            </a-row>
          </div>
        </div>
      </td>
    </div>
  </div>
  <div v-else-if="['shopee_id'].includes(fulfillmentChannelCode)">
    <div class="pagebreak">
      <td class="page-section shipping-label" style="height: 563pt">
        <div class="unboxing-warning" style="margin-bottom: 10px">
          <img src="@/assets/images/unboxing-warning.webp" width="100%" />
        </div>
        <div class="label-shipment-content-border mt-2">
          <div class="my-2">
            <div class="d-flex justify-content-between align-items-center px-2">
              <div class="w-25">
                <img
                  width="100%"
                  height="25"
                  style="object-fit: contain; object-position: left;"
                  :src="getMarketPlaceLogo(order?.channel?.code)"
                  alt="MP Logo"
                />
              </div>
              <div class="w-25 text-center">
                <div>{{ shippingAttribute?.shipping_carrier || shipping?.courier || '' }}</div>
                <div><b>{{ shipping?.service_type || '' }}</b></div>
              </div>
              <div class="w-25 text-right">
                <img
                  width="100%"
                  height="25"
                  style="object-fit: contain; object-position: right;"
                  :src="getCourierLogo(shipping?.courier)"
                  alt="Courier Logo"
                />
              </div>
            </div>
          </div>
          <template v-if="isAwbExist">
            <div class="d-flex justify-items-center text-center justify-content-center w-100">
              <div class="d-flex w-100 mx-2 mb-1">
                <div class="bold awb-tag-name py-1" style="margin-top: 0px; width: 33.33%; height: 100%; align-content: center;">
                  {{ shippingAttribute?.recipient_sort_code?.first_recipient_sort_code || '' }}
                </div>
                <div class="bold awb-tag-name py-1" style="margin-top: 0px; width: 33.33%; border-left: none; border-right: none; height: 100%; align-content: center;">
                  {{ shippingAttribute?.deliver_area || '' }}
                </div>
                <div class="bold awb-tag-name py-1" style="margin-top: 0px; width: 33.33%; height: 100%;">
                  <div style="font-weight: normal; letter-spacing: 0px;">
                    Tracking No:
                  </div>{{ shipping.awb }}
                </div>
              </div>
            </div>
            <div class="d-flex justify-items-center text-center justify-content-center mb-3 w-100">
              <VueBarcode
                v-model="shipping.awb"
                :options="barcode"
                style="max-width: 500px; max-height: 80px;"
              />
            </div>
          </template>
          <a-row
            class=" mx-2"
            style="
              padding-top: 10px;
              font-size: 12px;
              text-align: left;
              border-top: 1px dashed #cccccc;
            "
          >
            <a-col :span="16">
              <a-row
                class="d-flex"
              >
                <a-col :span="24" style="text-align: left; font-size: 12px; width: fit-content;">
                  <div style="font-weight: bold">
                    <div>Pengirim:</div>
                    <div class="mt-1">
                      {{ sender?.name || shipment?.name || salesData?.business?.business_title || '' }}
                    </div>
                  </div>
                  <div class="d-flex">
                    {{ sender?.address || shipment?.address || '' }}
                  </div>
                  <div class="d-flex">
                    {{ sender?.phone || shipment?.phone || '' }}
                  </div>
                </a-col>
              </a-row>
              <a-row class="d-flex mt-4">
                <a-col :span="24" style="text-align: left; font-size: 12px; width: fit-content;">
                  <div class="d-flex" style="font-weight: bold">
                    Penerima:
                  </div>
                  <div class="d-flex mt-1">
                    <img
                      :src="shippingAttribute?.recipient_address_info_list?.name"
                      width="100%"
                      height="100%"
                      style="object-fit: contain; object-position: left; max-height: 45px; max-width: 120px;"
                    />
                  </div>
                  <div class="d-flex">
                    <img
                      :src="shippingAttribute?.recipient_address_info_list?.phone"
                      width="100%"
                      height="100%"
                      style="object-fit: contain; object-position: left; max-height: 25px;"
                    />
                  </div>
                  <div class="d-flex">
                    <img
                      :src="shippingAttribute?.recipient_address_info_list?.full_address"
                      width="100%"
                      height="100%"
                      style="object-fit: contain; object-position: left; "
                    />
                  </div>
                </a-col>
              </a-row>
            </a-col>
            <a-col :span="8">
              <div v-if="shippingAttribute?.return_sort_code?.return_first_sort_code" class="bold awb-tag-name py-1 text-center" style="margin-top: 0px;">
                {{ shippingAttribute?.return_sort_code?.return_first_sort_code }}
              </div>
              <div v-if="shippingAttribute?.deliver_area" class="bold awb-tag-name py-1 text-center mt-2" style="margin-top: 0px;">
                {{ shippingAttribute?.deliver_area }}
              </div>
              <div v-if="isAwbExist" class="text-center mt-2">
                <qrcode-vue :value="shipping?.awb" :size="70" level="H" />
              </div>
              <div v-if="shippingAttribute?.order_weight" class="text-right" style="font-size: 10px;">
                Weight: <b>{{ shippingAttribute?.order_weight }}</b> gr
              </div>
            </a-col>
          </a-row>

          <div v-if="destination.address" class="d-flex justify-items-center text-center justify-content-center w-100">
            <div class="d-flex w-100 mx-2 mt-4" style="gap: 10px;">
              <div class="bold awb-tag-name py-1" style="margin-top: 0px; width: 33.33%; height: 100%;">
                {{ destination.address?.city?.toUpperCase() || '' }}
              </div>
              <div class="bold awb-tag-name py-1" style="margin-top: 0px; width: 33.33%; height: 100%;">
                {{ destination.address?.district?.toUpperCase() || '' }}
              </div>
              <div class="bold awb-tag-name py-1" style="margin-top: 0px; width: 33.33%; height: 100%;">
                {{ shippingAttribute?.spx_sub_district || '' }}
              </div>
            </div>
          </div>
          
          <div
            v-if="printFulfillmentId"
            class="conditional-pagebreak"
            style="
              margin-top: 10px;
              font-weight: 600;
              font-size: 14px;
              text-align: center;
              border-top: 1px dashed #cccccc;
            "
          >
            <a-row :gutter="16">
              <a-col :span="24">
                <div v-if="isExist" style="margin-top: 10px">
                  <VueBarcode
                    v-model="order.order_number"
                    :options="barcode"
                    style="max-width: 500px; max-height: 80px;"
                  />
                </div>
                <div v-if="isExist" style="margin-bottom: 15px;">
                  {{ order.order_number }}
                </div>
              </a-col>
            </a-row>
          </div>
          <div
            v-if="printOrder"
            class="conditional-pagebreak"
            style="
              margin: 10px 0px;
              font-size: 12px;
              text-align: left;
              border-top: 1px solid #cccccc;
            "
          >
            <a-row class="d-flex" style="width: 100%">
              <a-col :span="20" style="margin: 10px 10px">
                <div>Produk</div>
              </a-col>
              <a-col :span="2" style="margin: 10px 0">
                <div>Jumlah</div>
              </a-col>
            </a-row>
            <a-row v-for="(item, index) in product" :key="index" class="d-flex" style="width: auto;">
              <a-col :span="20" style="margin: 1px 10px">
                <div>{{ item.catalog_title }}</div>
              </a-col>
              <a-col :span="2" style="margin: 1px 0">
                <div style="text-align: center">
                  <b>{{ item.quantity }}</b> {{ item.uom }}
                </div>
              </a-col>
            </a-row>
          </div>
          <div
            class="p-2"
            style="
              margin-top: 10px;
              font-weight: 600;
              font-size: 12px;
              border-top: 1px dashed #cccccc;
            "
          >
            <a-row :gutter="16">
              <a-col :span="24">
                <div>{{ !!shipping?.code_value?.value ? '' : 'NON ' }}COD</div>
              </a-col>
            </a-row>
          </div>
        </div>
      </td>
    </div>
  </div>
  <div v-else>
    <div class="pagebreak">
      <td class="page-section shipping-label" style="height: 563pt">
        <div class="unboxing-warning" style="margin-bottom: 10px">
          <img src="@/assets/images/unboxing-warning.webp" width="100%" />
        </div>
        <div class="label-shipment-content-border mt-2">
          <div class="my-2">
            <div class="d-flex justify-content-between align-items-center px-2">
              <div class="w-25">
                <img
                  width="100%"
                  height="25"
                  style="object-fit: contain; object-position: left;"
                  :src="getMarketPlaceLogo(order?.channel?.code)"
                  alt="MP Logo"
                />
              </div>
              <div class="w-25 text-center">
                <div>{{ shippingAttribute?.shipping_carrier || shipping?.courier || '' }}</div>
                <div><b>{{ shipping?.service_type || '' }}</b></div>
              </div>
              <div class="w-25 text-right">
                <img
                  width="100%"
                  height="25"
                  style="object-fit: contain; object-position: right;"
                  :src="getCourierLogo(shipping?.courier)"
                  alt="Courier Logo"
                />
              </div>
            </div>
          </div>
          <template v-if="isAwbExist">
            <div class="d-flex justify-items-center text-center justify-content-center w-100">
              <div class="d-flex w-100 mx-2 mb-1">
                <div class="bold awb-tag-name py-1" style="margin-top: 0px; width: 33.33%; height: 100%; align-content: center;">
                  {{ shippingAttribute?.recipient_sort_code?.first_recipient_sort_code || '' }}
                </div>
                <div class="bold awb-tag-name py-1" style="margin-top: 0px; width: 33.33%; border-left: none; border-right: none; height: 100%; align-content: center;">
                  {{ shippingAttribute?.deliver_area || '' }}
                </div>
                <div class="bold awb-tag-name py-1" style="margin-top: 0px; width: 33.33%; height: 100%;">
                  <div style="font-weight: normal; letter-spacing: 0px;">
                    Tracking No:
                  </div>{{ shipping.awb }}
                </div>
              </div>
            </div>
            <div class="d-flex justify-items-center text-center justify-content-center mb-3 w-100">
              <VueBarcode
                v-model="shipping.awb"
                :options="barcode"
                style="max-width: 500px; max-height: 80px;"
              />
            </div>
          </template>
          <a-row
            class=" mx-2"
            style="
              padding-top: 10px;
              font-size: 12px;
              text-align: left;
              border-top: 1px dashed #cccccc;
            "
          >
            <a-col :span="16">
              <a-row
                class="d-flex"
              >
                <a-col :span="24" style="text-align: left; font-size: 12px; width: fit-content;">
                  <div style="font-weight: bold">
                    <div>Pengirim:</div>
                    <div class="mt-1">
                      {{ sender?.name || shipment?.name || salesData?.business?.business_title || '' }}
                    </div>
                  </div>
                  <div class="d-flex">
                    {{ sender?.address || shipment?.address || '' }}
                  </div>
                  <div class="d-flex">
                    {{ sender?.phone || shipment?.phone || '' }}
                  </div>
                </a-col>
              </a-row>
              <a-row class="d-flex mt-4">
                <a-col :span="24" style="text-align: left; font-size: 12px; width: fit-content;">
                  <div class="d-flex" style="font-weight: bold">
                    Penerima:
                  </div>
                  <div class="d-flex mt-1">
                    {{ destination?.name || '-' }}
                  </div>
                  <div class="d-flex">
                    <template v-if="destination.address">
                      <div style="word-break: break-word;">
                        {{ destination.address?.line1 }}
                        {{ destination.address?.line2 }},
                        {{ destination.address?.sub_district }},
                        {{ destination.address?.district }},
                        {{ destination.address?.province }},
                        {{ destination.address?.postal_code }}
                      </div>
                    </template>
                    <template v-else>
                      -
                    </template>
                  </div>
                  <div class="d-flex">
                    {{ destination?.phone || '-' }}
                  </div>
                </a-col>
              </a-row>
            </a-col>
            <a-col :span="8">
              <div v-if="shippingAttribute?.return_sort_code?.return_first_sort_code" class="bold awb-tag-name py-1 text-center" style="margin-top: 0px;">
                {{ shippingAttribute?.return_sort_code?.return_first_sort_code }}
              </div>
              <div v-if="shippingAttribute?.deliver_area" class="bold awb-tag-name py-1 text-center mt-2" style="margin-top: 0px;">
                {{ shippingAttribute?.deliver_area }}
              </div>
              <div v-if="isAwbExist" class="text-center mt-2">
                <qrcode-vue :value="shipping?.awb" :size="70" level="H" />
              </div>
              <!-- <div class="text-right" style="font-size: 10px;">
                Weight: <b>{{ shippingAttribute?.order_weight || '-' }}</b>gr
              </div> -->
            </a-col>
          </a-row>

          <div v-if="destination.address" class="d-flex justify-items-center text-center justify-content-center w-100">
            <div class="d-flex w-100 mx-2 mt-4" style="gap: 10px;">
              <div class="bold awb-tag-name py-1" style="margin-top: 0px; width: 33.33%; height: 100%;">
                {{ destination.address?.city?.toUpperCase() || '' }}
              </div>
              <div class="bold awb-tag-name py-1" style="margin-top: 0px; width: 33.33%; height: 100%;">
                {{ destination.address?.district?.toUpperCase() || '' }}
              </div>
              <div class="bold awb-tag-name py-1" style="margin-top: 0px; width: 33.33%; height: 100%;">
                {{ shippingAttribute?.spx_sub_district || '' }}
              </div>
            </div>
          </div>
          
          <div
            v-if="printFulfillmentId"
            style="
              margin-top: 10px;
              font-weight: 600;
              font-size: 14px;
              text-align: center;
              border-top: 1px dashed #cccccc;
            "
          >
            <a-row :gutter="16">
              <a-col :span="24">
                <div v-if="isExist" style="margin-top: 10px">
                  <VueBarcode
                    v-model="order.order_number"
                    :options="barcode"
                    style="max-width: 500px; max-height: 80px;"
                  />
                </div>
                <div v-if="isExist" style="margin-bottom: 15px;">
                  {{ order.order_number }}
                </div>
              </a-col>
            </a-row>
          </div>
          <div
            v-if="printOrder"
            style="
              margin: 10px 0px;
              font-size: 12px;
              text-align: left;
              border-top: 1px solid #cccccc;
            "
          >
            <a-row class="d-flex" style="width: 100%">
              <a-col :span="20" style="margin: 10px 10px">
                <div>Produk</div>
              </a-col>
              <a-col :span="2" style="margin: 10px 0">
                <div>Jumlah</div>
              </a-col>
            </a-row>
            <a-row v-for="(item, index) in product" :key="index" class="d-flex" style="width: auto;">
              <a-col :span="20" style="margin: 1px 10px">
                <div>{{ item.catalog_title }}</div>
              </a-col>
              <a-col :span="2" style="margin: 1px 0">
                <div style="text-align: center">
                  <b>{{ item.quantity }}</b> {{ item.uom }}
                </div>
              </a-col>
            </a-row>
          </div>
          <div
            class="p-2"
            style="
              margin-top: 10px;
              font-weight: 600;
              font-size: 12px;
              border-top: 1px dashed #cccccc;
            "
          >
            <a-row :gutter="16">
              <a-col :span="24">
                <div>{{ !!shipping?.code_value?.value ? '' : 'NON ' }}COD</div>
              </a-col>
            </a-row>
          </div>
        </div>
      </td>
    </div>
  </div>
</template>

<script>
import { getFulfillmentDetail } from '@/api/fulfillment'
import { getWarehouseById } from '@/api/warehouse'
import {
  getShippingDetailWithAttribute,
} from '@/api/shipping'
import ArrowIcon from '@/components/Icons/Arrow.vue'
import VueBarcode from '@chenfengyuan/vue-barcode'
import getQueryParam from '@/utils/getQueryParam'
import QrcodeVue from 'qrcode.vue'
const LIST_CHANNEL_OFFLINE = [
  "b2b_offline",
  "offline",
  "zalora_id",
  "external_web",
  "whatsapp_id",
  "shopify_id",
]

export default {
  name: 'List',
  components: {
    ArrowIcon,
    VueBarcode,
    QrcodeVue,
  },
  mixins: [getQueryParam],
  props: {
    fulfillmentId: {
      type: String,
      default: "",
    },
    printOrder: {
      type: Boolean,
      default: true,
    },
  },
  data: function () {
    return {
      tokopedia: require('@/assets/integration/channel/tokopedia.png'),
      shopee: require('@/assets/integration/channel/Shopee.png'),
      lazada: require('@/assets/integration/channel/lazada.png'),
      blibli: require('@/assets/integration/channel/blibli.png'),
      defaultMP: require('@/assets/integration/channel/Default.png'),
      sicepat: require('@/assets/courier/sicepat.png'),
      anteraja: require('@/assets/courier/anteraja.png'),
      jne: require('@/assets/courier/jne.png'),
      sap: require('@/assets/courier/sap.png'),
      paxel: require('@/assets/courier/paxel.png'),
      gosend: require('@/assets/courier/gosend.png'),
      grabx: require('@/assets/courier/grabx.png'),
      jntCargo: require('@/assets/courier/jnt-cargo.jpg'),
      jntExpress: require('@/assets/courier/jnt-express.png'),
      lex: require('@/assets/courier/lex.jpeg'),
      ninjax: require('@/assets/courier/ninjax.png'),
      spx: require('@/assets/courier/spx.png'),
      kurirRekomendasi: require('@/assets/courier/kurir-rekomendasi.webp'),
      defaultCourier: require('@/assets/courier/default.png'),
      detail: [],
      product: [],
      destination: [],
      shipping: {},
      warehouseId: '',
      size: '',
      printFulfillmentId: true,
      shipment: {},
      order: {},
      sender: {},
      listChannelOfflineAndDistri: [
        ...LIST_CHANNEL_OFFLINE,
        'store',
        'distributor',
      ],
      shippingAttribute: {},
    }
  },
  computed: {
    barcodeSmall() {
      return {
        height: 40,
        width: 2,
        displayValue: false,
      }
    },
    barcode() {
      return {
        height: 100,
        width: 1,
        displayValue: false,
      }
    },
    topBarcode() {
      return this.shipping.awb !== null
        ? this.shipping.awb
        : this.shipping.booking_no
    },
    isExist() {
      return this.detail[0] && this.detail[0].fulfillment_number
    },
    isAwbExist() {
      return Boolean(this.shipping?.awb)
    },
    fulfillmentChannelCode() {
      return this.order?.channel?.code
    },
  },
  watch: {
  },
  async mounted() {
    this.fetchDetailFulfillmentBasedOrderId()
  },
  methods: {
    parseISOString(dateTime) {
      if (dateTime) {
        // const split = dateTime.split(/\D+/)
        // const newDate = new Date(
        //   split[0],
        //   --split[1],
        //   split[2],
        //   split[3],
        //   split[4],
        //   split[5],
        //   split[6],
        // )
        // return this.$moment(newDate).format('Do MMMM YYYY')
        const timestamp = parseFloat(dateTime);
        const momentDate = this.$moment.unix(timestamp);
        const formattedDate = momentDate.format('DD MMMM YYYY');
        return formattedDate
      } else {
        return ''
      }
    },
    getCourierLogo(param) {
      if (param.toLowerCase().includes('spx')) {
        return this.spx
      } else if (param.toLowerCase().includes('kurir rekomendasi')) {
        return this.kurirRekomendasi
      } else if (param.toLowerCase().includes('anteraja')) {
        return this.anteraja
      } else if (param.toLowerCase().includes('sicepat')) {
        return this.sicepat
      } else if (param.toLowerCase().includes('jne')) {
        return this.jne
      } else if (param.toLowerCase().includes('gosend')) {
        return this.gosend
      } else if (param.toLowerCase().includes('grab')) {
        return this.grabx
      } else if (param.toLowerCase().includes('lex')) {
        return this.lex
      } else if (param.toLowerCase().includes('j&t')) {
        return this.jntExpress
      } else {
        return this.defaultCourier
      }
    },
    getMarketPlaceLogo(param) {
      switch (param.toLowerCase()) {
        case 'shopee_id':
          return this.shopee
        case 'shopee':
          return this.shopee
        case 'tokopedia_id':
          return this.tokopedia
        case 'tokpedia_id':
          return this.tokopedia
        case 'lazada_id':
          return this.lazada
        case 'blibli_id':
          return this.blibli
        default:
          return this.defaultMP
      }
    },
    setPrintFulfillmentId(e) {
      this.printFulfillmentId = e.target.checked
    },
    setPageSize(value) {
      const style = document.createElement('style')
      style.innerHTML = `@page {size: ${value}}`
      document.head.appendChild(style)
    },
    async fetchDetailFulfillmentBasedOrderId() {
      if (!this.fulfillmentId) {
        return
      }
      await getFulfillmentDetail({
        url: 'fulfillment',
        fulfillment_id: this.fulfillmentId,
      })
      .then(({ data: { data: response } }) => {
        this.detail = response.fulfillments
        this.order = response.order
        this.getDataDetail()
        this.fetchWarehouseId(response.warehouse_id)
        this.fetchShippingAttribute(response.order.id, response.business_id)
      })
      .catch(err => console.error(err))
    },
    fetchWarehouseId(warehouseId) {
      getWarehouseById(warehouseId)
      .then(({ data: { data: response } }) => this.shipment = response)
      .catch((err) => console.error(err))
    },
    fetchShippingAttribute(orderId, businessId) {
      getShippingDetailWithAttribute({
        order_id: orderId,
        business_id: businessId,
      })
      .then(({ data: { data: response } }) => {
        if (response?.attributes) {
          this.shippingAttribute = JSON.parse(response.attributes)
          console.log(JSON.parse(response.attributes))
        } else {
          this.shippingAttribute = {}
        }
      })
      .catch((err) => console.error(err))
    },

    getDataDetail() {
      const label_id = this.fulfillmentId
      const data = this.detail.find(item => item.id === label_id)
      this.product = data.line_items
      this.destination = data.destination
      this.shipping = data.shipping
      this.sender = data.sender
    },
    back() {
      this.$router.push({
        name:
          this.$route.params.id === undefined
            ? 'fulfillment.index'
            : 'channel-fulfillment',
        query: {
          ...this.getQueryParam(this.$route.query),
        },
      })
    },
  },
}
</script>

<style lang="scss">
@page {
  // size: A5;
  margin: 0;
}

html {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  line-height: 1.2;
}

body {
  font-family: 'Open Sans', sans-serif;
  padding: 0;
  line-height: 1;
  width: 100%;
  display: block;
  margin: 0 auto;
  color: #000;
  font-size: 9pt;
  background: white;
}
img {
  -ms-interpolation-mode: bicubic;
}

table {
  max-width: 100%;
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  table-layout: fixed;
}

table.table-bordered tr td {
  border: 1px solid #000;
}

table th {
  text-align: left;
}

table td {
  vertical-align: top;
}

@page {
  size: A5;
  padding: 10px;
}
.page {
  width: 100%;
  position: relative;
  /* display: inline-block; */
  vertical-align: top;
  padding: 0;
  // overflow: hidden;
  margin: auto;
  margin-top: 160px;
  height: 200mm;
  width: 140mm;
}

.label-shipment {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
  width: auto;
  height: auto;
  background: #ffffff;
  border: 2px solid black;
}

.page-section {
  padding: 8pt;
  width: 50%;
  max-width: 50%;
}

/* Page Breaker */
.page-breaker {
  position: relative;
  text-align: center;
  page-break-before: always;
  margin-bottom: 20px;
}

.barcode {
  width: 100%;
}
.shipping-label {
  width: 43%;
  max-width: 43%;
}
.shipping-label table {
  border: 1px solid #444;
}
.shipping-label table td {
  padding: 4px 8px;
}

.label-shipment-content-border {
  border: 1px solid #000000;
}

.awb-tag-name {
  border: 1px solid #000000;
  width: 100%;
  letter-spacing: 2px;
  font-weight: bold;
}

.package-slip {
  width: 57%;
  max-width: 57%;
}
.package-slip table {
  font-size: 8pt;
}
.package-slip table td {
  border-bottom: 1px solid #e1e1e1;
  padding: 5px 0 5px;
}

.package-slip table tr:last-child td {
  border-bottom: 0;
}

tr.dashed-bottom > td {
  border-bottom: 1.5px dashed #d1d1d1;
}
tr.dashed-top > td {
  border-top: 1.5px dashed #d1d1d1;
}

.bold {
  font-weight: bold;
}
.italic {
  font-style: italic;
}
.light-italic {
  font-style: italic;
  font-weight: 300;
}

.center {
  text-align: center;
}
.left {
  text-align: left;
}
.right {
  text-align: right;
}

.v-top {
  vertical-align: top;
}
.v-middle {
  vertical-align: middle;
}
.v-bottom {
  vertical-align: bottom;
}

.w-25 {
  width: 25%;
  min-width: 25%;
  max-width: 25%;
}
.w-50 {
  width: 50%;
  min-width: 50%;
  max-width: 50%;
}

.fs-6 {
  font-size: 6pt;
}
.fs-7 {
  font-size: 7pt;
}
.fs-8 {
  font-size: 8pt;
}
.fs-9 {
  font-size: 9pt;
}
.fs-10 {
  font-size: 10pt;
}
.fs-11 {
  font-size: 11pt;
}
.fs-12 {
  font-size: 12pt;
}

.fs-14 {
  font-size: 14pt;
}
.fs-16 {
  font-size: 16pt;
}

.text-muted {
  color: #404040;
}

canvas {
  width: 100%;
  height: fit-content;
}

.print-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed #333;
  padding: 2rem;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 2rem;
  max-width: 290mm;
  margin: 2rem auto;
  text-transform: uppercase;
  opacity: 0.5;
  margin-top: 10px;
  margin-bottom: 10px;
}

.print-button img {
  margin-right: 1rem;
}

.header-print {
  position: fixed;
  width: 100%;
  top: 0;
  background: white;
  z-index: 10;
  padding: 5px 0px;
}

.conditional-pagebreak {
  page-break-inside: avoid;
}

@media only print {
  // .print-button {
  //   display: none;
  // }

  // .header-print {
  //   display: none;
  // }

  .pagebreak {
    page-break-before: always;
  }

  .page {
    margin: 0;
  }
}
</style>
